#header {
  height: 120px;
  text-align: right;
  position: relative;
  text-shadow: 0 1px 0 rgba($white, 0.4);
  padding-top: 15px;

  .logo {
    margin: 0;

    strong {
      position: absolute;
      left: -1000px;
    }

    small {
      position: absolute;
      left: -1000px;
    }

    .logo-link {
      display: block;
      position: absolute;
      top: 30px;
      left: 60px;
    }

    .logo-image {
      display: block;
    }
  }

  p {
      font-size: 18px;
      margin-bottom: 0;
      color: #0f6599;
  }
  a {
      color: #0f6599;

      &:hover {
          color: #1380c3;
          text-decoration: none;
      }
  }
  .search {
      position: absolute;
      right: 15px;
      top: 75px;
      text-align: right;
      width: 300px;
      //
      label {
          display: none;
      }
  }
}
