#bottom-menu {
  color: $dove-gray;
  text-shadow: 0 1px 0 rgba($white, 0.4);
  background: {
    color: $silver;
    image: url(../gfx/ticks_dark.png);
    repeat: repeat;
    position: 0 0;
  };
  padding-top: 20px;

  .navbar-nav,
  .nav-wrap {
    width: 600px;
    font-size: 18px;
    float: left;

    li {
      width: 200px;

      a {
        color: $dove-gray;
        margin: 0;
        padding: 0;

        &:hover {
          background-color: transparent;
          color: $dusty-gray;
        }
      }
    }
  }
  .box {
    margin: 0 0 0 700px;
    width: 300px;

    p {
      margin: 0;
      position: relative;
      padding: 0 0 0 22px;

    }

    i {
      position: absolute;
      top: 3px;
      left: 0;
    }

    a {
      color: $dove-gray;

      &:hover {
        color: $dusty-gray;
        text-decoration: none;
      }
    }
  }

  .partnership {
    width: 100%;
    margin-top: 20px;
    background-color: $white;
    text-align: center;
  }
}
