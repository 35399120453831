@import "./../node_modules/bootstrap/scss/bootstrap";
@import "variables";
@import "elements";
@import "header";

@mixin regular(){
  font-weight: 400;
}
@mixin semi-bold(){
  font-weight: 600;
}
@mixin bold(){
  font-weight: 700;
}
@mixin clearfix(){
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}

@mixin defaultFontSize(){
  font-size: 16px;
}

body {
  background: $gray url(../gfx/ticks.png);
  font-family: 'Source Sans Pro', sans-serif;
  @include regular;
  @include defaultFontSize;
  color: $mine-shaft;
  line-height: 1.6;
}

a {
  color: #06517f;
}

a:hover .pic,
a:hover .pic_left,
a:hover .pic_right,
a:hover .gal {
  //@include box-shadow(0 0 5px #06517f);
}

h1,
h2,
h3,
h4,
h4,
h6 {
  font-family: 'Source Sans Pro', sans-serif;
  @include semi-bold;
}

iframe {
  border: none;
}

.btn-primary,
.btn-primary.active {
  background: linear-gradient($guardsman-red2, $guardsman-red);
  border: 0;
  text-shadow: 0 1px 0 $bright-red;

  &:hover {
    background: linear-gradient($monza, $red);
  }

  i {
    margin-left: 4px;
  }
}

.btn-xs {
  padding: 2px 7px;
  @include defaultFontSize;
}

.page-title {
  background: {
    image: url(../gfx/header_1.jpg);
    position: bottom center;
    size: cover;
    repeat: no-repeat;
  };
  margin: -20px -20px 20px -20px;
  height: 180px;
  text-shadow: 0 1px 0 $catalina-blue;

  span {
    display: inline-block;
    margin-top: 100px;
    background: linear-gradient($denim, $tory-blue);
    color: $white;
    padding: 3px 20px 3px 20px;
    @include border-radius(4px, 4px, 0, 0);
  }
}

.lead {
  margin-bottom: 10px;
  @include defaultFontSize;
  @include regular;
}

.date {
  clear: both;
  display: block;
  font-size: 12px !important;
  color: #a1a1a1;
  margin: 0;
}

.article-title {
  margin: 0;
  font-size: 28px;
  margin: 6px 0 8px 0;
  color: $guardsman-red2;

  a {
    color: $guardsman-red2;
  }
  a:hover {
    color: #f50002;
    text-decoration: none;
  }
}

.news-title {
  clear: both;
  font-size: 24px;
  margin: 6px 0 8px 0;

  a {
    color: $guardsman-red2;

    &:hover {
      color: #f50002;
      text-decoration: none;
    }
  }
}

.more {
  text-align: right;
  clear: both;
}

.pic,
.pic_left,
.pic_right,
.gal {
  @include drop-shadow(0, 0, 5px, 0.2);

}

.download {
    display: inline-block;
    padding: 8px 7px 8px 30px;
    border: 1px solid #cfcfcf;
    background-color: $white;
    border-radius: 2px;
}

.pdf {
    background: url(../gfx/document_pdf_1.png) no-repeat 7px center;
}

@import "header";

@import "navigation";

#breadcrumb {
    background: transparent;
    padding: 0;
    color: $dove-gray;
    margin-bottom: 15px;

    a {
        color: $dove-gray;
    }
    i {
        margin: 0 4px;
    }
}

.news-header {
    border-radius: 2px 0 0 2px;
    background-color: $denim;
    padding: 8px 20px;
    margin: 0 0 0 0;
    color: $white;
    font-size: 22px;
    margin: -20px -20px 18px -20px;
    @include regular;

    a {
        color: $white;
    }

    a:hover {
        text-decoration: none;
    }

    &.promotions {
        background-color: $guardsman-red;
    }
}
.news-feed,
.introduction,
.page-content {
    padding: 20px;
    background: $white;
    border-radius: 2px;
    margin-bottom: 20px;
    overflow: hidden;
}
.news-feed {
    overflow: hidden;
    //
    .date {
        clear: both;
    }
    .lead {
        text-align: justify;
        @include defaultFontSize;
        //
        img.pic {
            float: left;
            margin: 5px 20px 20px 0;
            //@include drop-shadow(0, 0, 10px, 0.2);
            //@include rounded(4px);
        }
    }
}
/* */
/* HOMEPAGE INTRODUCION */
/* */
.introduction {
    //
    h2 {
        @include regular();
        color: $guardsman-red2;
    }
    h3 {
        @include semi-bold();
        color: $tory-blue;
    }
    p {
        text-align: justify;
    }
    .lead {
        @include defaultFontSize;
        @include regular;
    }
}
/* */
/* SUBPAGES */
/* */
#content.subpage {
    //
    .news-feed .news-title {
        font-size: 20px;
    }
    .blog {
        .page-subtitle {
            margin-bottom: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid $gray;
        }
        .story {
            border-bottom: 1px solid $gray;
            margin-bottom: 20px;
            @include clearfix;
            //
            &:last-child {
                border: 0;
                margin-bottom: 0;
            }
        }
        .pic {
            float: left;
            margin: 3px 20px 10px 0;
        }
        .lead {
            @include defaultFontSize;
        }
        &.products-list {
            .items-leading {
                .img-intro-left {
                    float: left;
                    margin-bottom: 20px;
                    margin-right: 20px;
                    //
                    img {
                        @include rounded(3px);
                    }
                }
                .news-title {
                    clear: none;
                    margin-top: 0;
                }
            }
            .category-desc {
                margin-bottom: 20px;
            }
        }
    }
    .news-feed .pic {
        display: none;
    }
}
.page-article,
.category-desc {
  p {
    font-size: 16px;
    text-align: justify;
  }

  ul,
  ol {
    font-size: 16px;
    text-align: justify;
  }

  h3 {
    color: $guardsman-red2;
    font-size: 24px;
  }

  .lead {
    font-size: 16px;
    @include semi-bold;
    margin-bottom: 15px;
  }

  .question {
    font-style: italic;
    background: #f0f0f0;
    border-radius: 3px;
    padding: 12px 15px;
  }

  .pic {
    display: none;
  }

  .pic_left {
    float: left;
    margin: 5px 25px 10px 0;
  }

  .pic_right {
    float: right;
    margin: 5px 0 10px 25px;
  }

  .gal {
    margin: 3px 10px 10px 0;
  }
}

@import "bottom-menu";

#foot {
  color: $dove-gray;
  text-shadow: 0 1px 0 rgba($white, 0.4);
  padding: 10px 0 15px 0;

  .copy {
    display: inline-block;
    padding-top: 9px;
  }

  img {
    float: right;
    margin-left: 8px;
  }

  .author {
    padding-top: 9px;
    float: right;
  }
}
