$guardsman-red: #c20001;
$monza: #da0002;
$red: #e10002;
$bright-red: #a80000;
$guardsman-red2: #cc0001;

$tory-blue: #0f6599;
$catalina-blue: #074074;
$denim: #1374a8;

$gray: #ebebeb;
$dove-gray: #737373;
$mine-shaft: #3b3b3b;
$dusty-gray: #959595;
$silver: #cccccc;

$font-weigh-regular: 400;
$font-weigh-medium: 600;
$font-weigh-bold: 700;
