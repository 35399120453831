#nav {
  background: transparent;
  min-height: 38px;
  margin-bottom: 0.5rem;
  font-size: 17px;
  border: none;

  .nav-wrap {
    background-image: linear-gradient($guardsman-red2, $guardsman-red);
    min-height: 38px;
    border-radius: 2px;
    padding: 0 1rem;
    width: 100%;
  }

  .nav {
    flex-wrap: nowrap;
    justify-content: space-around;

    > li {
      white-space: nowrap;

      > a {
        color: $white;
        padding: 9px 1rem 9px 1rem;
        background-image: linear-gradient($guardsman-red2, $guardsman-red);
        position: relative;
        z-index: 5;

        &:hover {
          background-image: none;
          background-color: $tory-blue;
        }
      }
    }
    > li.dropdown {
      &:hover {
        > .dropdown-menu {
          display: block;
        }
        > a.nav-link {
          position: relative;
          z-index: 5;
          background-image: none;

          &:hover {
            background-image: none;
          }
        }
      }

      &:last-child {
        .dropdown-menu {
          left: auto;
          right: 0;
        }
      }
    }

    > li.active > a,
    > li.active > a:hover,
    > li.active > a:focus {
      // background-image: linear-gradient($denim, $tory-blue);
    }

    > li > .dropdown-menu {
      border: 0;
      margin-top: 0;
      background-color: $tory-blue;
      border-radius: 0 0 2px 2px;
      box-shadow: 0 0 10px rgba($black, 0.1);
      top: 0;
      padding-top: 50px;
      z-index: 4;

      > li {
        > a.nav-link {
          padding: 0.25rem 1rem;
          color: $white;

          &:hover {
            background: #178abe;
          }
        }
      }

      > li.dropdown:hover > .dropdown-menu {
        display: block;
      }

      > li > .dropdown-menu {
        top: -7px;
        left: 100%;
        border: 0;
        background-color: $tory-blue;
        border-radius: 0 2px 2px 0;
        box-shadow: 0 0 10px rgba($black, 0.1);
        z-index: 999;

        > li {
          a {
            padding: 0.25rem 1rem;
            color: $white;

            &:hover {
              background-color: #178abe;
            }
          }
        }

        > li.dropdown:hover > .dropdown-menu {
          display: block;
        }

        > li > .dropdown-menu {
          top: -7px;
          left: 100%;
          border: 0;
          background-color: $tory-blue;
          border-radius: 0 2px 2px 0;
          box-shadow: 0 0 10px rgba($black, 0.1);
          z-index: 999;
        }
      }
    }
  }
}
